import yes from "../assets/svgs/check-circle-fill-yes.svg";
import pushing from "../assets/pushing.png";
import asterisk from "../assets/svgs/asterisk.svg";
import placeholder from "../assets/svgs/check-circle-placeholder.svg";

export const details = [
  {
    title: "Founders Membership",
    points: [
      { text: "Unlimited Classes", symbol: yes, color: "black" },
      {
        text: "Open Gym Access",
        symbol: yes,
        color: "black",
      },
      {
        text: "2 Class Guests monthly",
        symbol: yes,
        color: "black",
      },
      {
        text: "Private training sessions at $70",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 150,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857909",
  },
  {
    title: "Golden Gloves",
    points: [
      { text: "Unlimited Classes", symbol: yes, color: "black" },
      {
        text: "Open Gym Access",
        symbol: yes,
        color: "black",
      },
      {
        text: "1 Class Guests monthly",
        symbol: yes,
        color: "black",
      },
      {
        text: " 10% discount on private training",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 170,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857949",
  },
  {
    title: "Silver Gloves",
    points: [
      {
        text: "1 Class Guests monthly",
        symbol: yes,
        color: "black",
      },
      {
        text: "10 Classes",
        symbol: yes,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
    ],
    img: pushing,
    price: 170,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857954",
  },
  {
    title: "Junior Gloves Up",
    points: [
      {
        text: "2 classes week",
        symbol: yes,
        color: "black",
      },
      {
        text: "12 to 17 years old",
        symbol: asterisk,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
    ],
    img: pushing,
    price: 160,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857971",
  },
  {
    title: "Kids Gloves Up",
    points: [
      {
        text: "1 classes week",
        symbol: yes,
        color: "black",
      },
      {
        text: "4 to 11 years old",
        symbol: asterisk,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
      {
        text: "",
        symbol: placeholder,
        color: "black",
      },
    ],
    img: pushing,
    price: 160,
    type: "monthly",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857972",
  },
  {
    title: "Class Package",
    points: [
      {
        text: `10 classes \n Use it at your rhythm \n Expires in 2 months`,
        symbol: "",
        color: "black",
      },
    ],
    img: pushing,
    price: 220,
    type: "open",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857977",
  },
  {
    title: "Jump In",
    points: [
      {
        text: "\u00A0\n 1 class \n \u00A0",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 25,
    type: "open",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857978",
  },
  {
    title: "Personal Training",
    points: [
      {
        text: "1 on 1 for 1 hr",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 100,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/schedule.php?owner=34028354&appointmentType=category:Personal%20Training",
  },
  {
    title: "Semi Private",
    points: [
      {
        text: "2 on 1 for 1 hr",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 110,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/schedule.php?owner=34028354&appointmentType=category:Personal%20Training",
  },
  {
    title: "Training Package A",
    points: [
      {
        text: "5 sessions",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 475,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857990",
  },
  {
    title: "Training Package B",
    points: [
      {
        text: "10 sessions",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: 900,
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857993",
  },
  {
    title: "Private Group Session",
    points: [
      {
        text: "3 to 6 Boxers",
        symbol: yes,
        color: "black",
      },
    ],
    img: pushing,
    price: "Please Contact Directly",
    type: "tailorMade",
    link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1857997",
  },
  {
    title: "Cold Plunge",
    points: [
      {
        text: "\u00A0",
      },
    ],
    img: pushing,
    price: 20,
    type: "tailorMade",
    link: "",
  },
  {
    title: "Photo/Movie Projects",
    points: [
      {
        text: "\u00A0",
      },
    ],
    img: pushing,
    price: "Please Contact Directly",
    type: "tailorMade",
    link: "",
  },
];
