import React from "react";
import Headers from "./Headers";
import { Container, Row, Col } from "react-bootstrap";
import MerchCard from "./MerchCard";
import shirt1 from "../assets/shirt1.png";
import shirt2 from "../assets/shirt2.png";
import shirt3 from "../assets/shirt3.png";
import sweater1 from "../assets/sweater1.png";
import sweater2 from "../assets/sweater2.png";
import sweater3 from "../assets/sweater3.png";
import sweater4 from "../assets/sweater4.png";
import pant1 from "../assets/pant1.png"

export default function Merch() {
  const merchBody = [
    {
      img: shirt1,
      name: "WHITE BOXING SHIRT",
      price: "$25",
      desc: "white t-shirt",
      link: "https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858071"
    },
    {
      img: shirt2,
      name: "BLACK BOXING SHIRT (CROPPED)",
      price: "$25",
      desc: "black t-shirt cropped",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
    {
      img: shirt3,
      name: "BOXING SHIRT GREY (CROPPED)",
      price: "$25",
      desc: "white t-shirt",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
    {
      img: sweater1,
      name: "BOXING SWEATER GREY (CROPPED)",
      price: "$25",
      desc: "white t-shirt",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
    {
      img: sweater2,
      name: "BOXING SWEATER GREY",
      price: "$30",
      desc: "white t-shirt",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
    {
      img: sweater3,
      name: "BOXING SWEATER BLACK",
      price: "$30",
      desc: "white t-shirt",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
    {
      img: sweater4,
      name: "BOXING SWEATER BLACK (CROPPED)",
      price: "$30",
      desc: "white t-shirt",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
    {
      img: pant1,
      name: "BOXING SWEATPANTS BLACK",
      price: "$30",
      desc: "white t-shirt",
      link:"https://app.acuityscheduling.com/catalog.php?owner=34028354&action=addCart&clear=1&id=1858065"
    },
  ];

  return (
    <div className="merch" id="home">
      <Headers title={"Merch"} />
      <div className="merch-body">
        <Container className="d-flex justify-content-center">
          <Row className="d-flex justify-content-center">
            {merchBody.map((obj, index) => (
              <Col key={index} xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
                <MerchCard merchBody={obj} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
